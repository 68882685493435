import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { Breadcrumbs } from "../components/Breadcrumbs";
import { ArticlePhotoRowLinks } from "../components/ArticlePhotoRowLinks";
import { Pagination } from "../components/Pagination";
import theme, { breakpoints } from "../style/theme";

export default function ResultsTagTemplate({
  data: {
    site: {
      siteMetadata: {
        siteUrl,
        templates: {
          posts: {
            pathPrefix,
            filters: {
              tag: {
                pathPrefixTag,
                pagination: { resultsPerPage },
              },
            },
          },
        },
      },
    },
    allMdx: { edges: posts },
  },
  pageContext: { tag, totalPages, currentPage },
}) {
  return (
    <Layout title={`#${tag}の記事一覧（${currentPage} / ${Math.ceil(totalPages / resultsPerPage)} Page）`} shownAdSense={false}>
      <Breadcrumbs
        links={[
          {
            url: `/${pathPrefix}/page/1`,
            title: 'BLOG',
          },
          {
            url: `/${pathPrefixTag}/${tag}/page/1`,
            title: `#${tag}`,
          },
          {
            url: null,
            title: `${currentPage} / ${Math.ceil(totalPages / resultsPerPage)} Page`,
          }
        ]}
      />
      <ArticlePhotoRowLinks
        edges={posts}
        fontColor={theme.palette.white}
        backgroundColor={theme.palette.primary.main}
        breakpoint={breakpoints.sm}
      />
      <Pagination
        url={`/${pathPrefixTag}/${tag}/page`}
        currentPage={currentPage}
        totalPages={Math.ceil(totalPages / resultsPerPage)}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!, $tag: String!) {
    site {
      siteMetadata {
        siteUrl
        templates {
          posts {
            pathPrefix
            filters {
              tag {
                pathPrefixTag
                pagination {
                  resultsPerPage
                }
              }
            }
          }
        }
      }
    }
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/content/posts/" }
        frontmatter: { tags: { in: [$tag] } }
      }
      sort: { order: DESC, fields: [fileAbsolutePath] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 50, truncate: true)
          fileAbsolutePath
          frontmatter {
            id
            title
            category
            description
            tags
            createdDate
            updatedDate
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
